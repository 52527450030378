import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation, Trans } from 'react-i18next'
import styled from 'styled-components'

import { Button, ButtonType } from '@junglescout/edna'
import { TEXT_STYLES } from 'TEXT_STYLES'

const AccountSyncErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
  margin: 20px 0;
  padding: 20px 15px;
  img {
    width: 300px;
    height: 150px;
    margin-bottom: 15px;
  }
`
const Title = styled.h1`
  ${TEXT_STYLES.Coaching1};
  color: ${props => props.theme.colors.grey700};
  margin: 0;
`
const Subtitle = styled.h2`
  ${TEXT_STYLES.Coaching2Grey};
  color: ${props => props.theme.colors.grey700};
  font-weight: normal !important;
  padding-top: 15px;
  padding-bottom: 20px;
  margin: 0;
  a {
    color: ${props => props.theme.colors.primary};
  }
`

export const AccountSyncError = ({
  btnType,
  type,
  handleButtonClick,
  btnDataId
}) => {
  const { t } = useTranslation('mwsSync')
  const isPpc = type === 'ppc'
  const iconLink = isPpc
    ? 'https://djga94vwgu7gt.cloudfront.net/assets/junglescout/ppc-sync-failure@3x.png'
    : 'https://djga94vwgu7gt.cloudfront.net/assets/junglescout/mws-sync-failure@3x.png'
  const howToLink = isPpc
    ? 'https://support.junglescout.com/hc/en-us/articles/360036000974'
    : 'https://support.junglescout.com/hc/en-us/articles/360021110574'

  return (
    <AccountSyncErrorWrapper>
      <img src={iconLink} alt="sync error" />
      <Title>
        {t('mwsSync:AccountSyncError.title', "Oops, let's try again...")}
      </Title>
      <Subtitle>
        <Trans i18nKey="mwsSync:AccountSyncError.subtitle">
          Please check you have entered your login information correctly. Follow
          this{' '}
          <a href={howToLink} target="_blank" rel="noopener noreferrer">
            how-to article
          </a>{' '}
          if you have multiple accounts.
        </Trans>
      </Subtitle>
      <Button
        btnType={btnType}
        onClick={() => handleButtonClick()}
        dataId={btnDataId}>
        {t('mwsSync:AccountSyncError.retryButton', 'Try Connecting Again')}
      </Button>
    </AccountSyncErrorWrapper>
  )
}

AccountSyncError.defaultProps = {
  btnDataId: '',
  btnType: ButtonType.PRIMARY
}

AccountSyncError.propTypes = {
  btnType: PropTypes.string,
  type: PropTypes.oneOf(['ppc', 'mws']).isRequired,
  handleButtonClick: PropTypes.func.isRequired,
  btnDataId: PropTypes.string
}
